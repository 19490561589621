import React from 'react'
import { UniversalNavV2 } from 'tn-components-library'

import { GetInsights } from '../../http/insights'
import ViewAs from './viewAs'
import './styles.css'
import KeyCloakAuthInstance from '../../auth/keyAuth'
import { getRolesFromProductDetails } from '../../utils'

const SideNav = () => {
  const [isViewAsModalOpen, setViewAsModalOpen] = React.useState(false)
  const insightsData = GetInsights()
  const roles = getRolesFromProductDetails(window.Gemini.UserData.productDetails)
  const args = {
    roles,
    planAccess: window.Gemini.UserData.PLAN2x,
    recruitAccess: window.Gemini.UserData.GEMINI,
    customRoleAccess: window.Gemini.UserData.customRoleEnabled,
    dataFeedAccess: window.Gemini.UserData.dataFeedEnabled,
    adminAcess: window.Gemini.UserData.adminEnabled,
    isAlertsEnabled: window.Gemini.UserData.isAlertsEnabled,
    isStaffing: window.Gemini.UserData.staffingEnabled,
    activeProduct: window.location.href.indexOf("requestapiaccess") >= 0 ? 'axon' : 'home',
    urls: {
      customrole: window.Gemini.Config.customRoleURL,
      insight: `${window.Gemini.Config.homeURL}/learnmore/insights`,
      learnmore: `${window.Gemini.Config.homeURL}/learnmore`,
      plan: `${window.Gemini.Config.plan2xURL}#`,
      recruit: `${window.Gemini.Config.recuritURL}#`,
      datafeeds: window.Gemini.Config.dataFeedURL,
      admin: window.Gemini.Config.adminURL,
      myaccount: `${window.Gemini.Config.homeURL}/home/myaccount`,
      home: window.Gemini.Config.homeURL,
      password: () => onPasswordChangeHandler(),
      customSolution: `${window.Gemini.Config.homeURL}/customsolutions`,
      axon: `${window.Gemini.Config.homeURL}/home/requestapiaccess`,
      staffing: window.Gemini.Config.staffingURL,
    },
    insights: insightsData,
    ssoEnabled: !!window.Gemini.UserData.ssoenabled
  }
  const logout = () => {
    KeyCloakAuthInstance.logout();
  }
  const onPasswordChangeHandler = () => {
    const url = `${KeyCloakAuthInstance.getUpdatePasswordURL()}`
    window.open(url, '_self');
  }
  const menuClickCallback = (menuItem) => {
    console.log(menuItem)
    switch (menuItem.id) {
      case 'viewas': {
        setViewAsModalOpen(true)
        break
      }
      case 'logout': {
        logout()
        break
      }
      default: {
        break
      }
    }
  }
  return (
    <>
      <UniversalNavV2 {...args} menuClickCallback={menuClickCallback} />
      {isViewAsModalOpen && <ViewAs setViewAsModalOpen={setViewAsModalOpen} />}
    </>
  )
}
export default SideNav
